<template>
	<div class="container-fluid">
		<h3 style="text-align: center;">ROBBO EDITOR {{levelData.id}}</h3>

		<div class="alert alert-danger" v-if="error">{{ error }}</div>

		<div class="row">
			<div class="col-sm">
				<div id="left-menu" style="position: absolute; font-size: 12px;">
					<table
						id="bricks-bar"
						style="border: 1px solid red;"
						cellpadding="0"
						cellspacing="0"
					>
						<!-- bricks here -->
					</table>

					<button
						class="btn btn-danger m-2"
						@click="$bvModal.show('test-modal')"
					>
						Test level
					</button>

				</div>
				<table cellpadding="0" cellspacing="0" id="editor-grid"></table>
			</div>

			<div class="col-sm text-left">
				<form @submit.prevent="saveLevel()">
					<div class="form-group">
						<label>Level name</label>
						<input type="text" class="form-control" v-model="levelData.name" :class="{'is-invalid':!levelData.name}">
					</div>
					<div class="form-group">
						<label>Description</label>
						<textarea
							type="text"
							class="form-control"
							v-model="levelData.note"
							style="font-size: 0.8em; min-height: 200px"
						></textarea>
					</div>
					<div class="form-group">
						<div class="form-check">
							<input
								id="dif-active-1"
								class="form-check-input"
								type="radio"
								name="active"
								value="1"
								v-model="levelData.active"
							/>
							<label class="form-check-label" for="dif-active-1">
								Active
							</label>
						</div>
						<div class="form-check">
							<input
								id="dif-active-0"
								class="form-check-input"
								type="radio"
								name="active"
								value="0"
								v-model="levelData.active"
							/>
							<label class="form-check-label" for="dif-active-0">
								Not active
							</label>
						</div>
					</div>

					<div class="form-group">
						<label>Difficulty</label>
						<select class="form-control" v-model="levelData.difficulty">
							<option v-for="v in [0, 1, 2, 3, 4, 5]" :key="v" :value="v">{{
								v
							}}</option>
						</select>
					</div>

					<button type="submit" class="btn btn-primary" :disabled="!isSaveEnabled" v-if="levelData.id">Update</button>
					<button type="submit" class="btn btn-primary" :disabled="!isSaveEnabled || !levelData.name" v-if="!levelData.id">Create</button>
					<button type="button" class="btn btn-primary ml-2" :disabled="!isSaveEnabled" @click="confirmSave()" v-if="levelData.id">Save as new</button>
					<button type="button" class="btn btn-danger ml-2" :disabled="!isSaveEnabled" @click="confirmDelete()" v-if="levelData.id">Delete</button>
				</form>
			</div>
		</div>

		<!-- here are rows and cells -->
		<!-- <br clear="all" /> -->
		<!-- <div style="text-align: center; width: 580px; margin: 0 auto;">
			<div id="robbo-game" style="clear: left;">test here</div>
		</div> -->

		<b-modal
			id="test-modal"
			size="lg"
			@shown="testLevel"
			hide-footer
			body-bg-variant="light"
			@hide="clearTest"
		>
			<template #modal-title> Test level </template>
			<div class="text-center">
				<canvas
					width="576px"
					height="448px"
					style="background-color: cadetblue;"
					id="game-canvas"
				></canvas>
			</div>
		</b-modal>
	</div>
</template>

<style lang="scss">
#game-canvas {
	// position: absolute;
}
div#editor-grid {
	background: gray;
	min-height: 300px;
}
#editor-grid {
	// margin: 30px auto 30px auto;
	margin-left: 160px;
}
.editor-row {
	height: 32px;
	padding: 0;
	margin: 0;
}
.editor-cell {
	padding: 0;
	margin: 0;
	font-size: 10px;
	color: white;
	height: 31px;
	width: 31px;
	border-left: 1px dotted #444;
	border-top: 1px dotted #444;
}
.most-right-cell {
	border-right: 1px dotted #444;
	width: 30px;
}
.most-bottom-cell {
	border-bottom: 1px dotted #444;
	height: 30px;
}
.editor-cell:hover {
	height: 30px;
	width: 30px;
	border: 1px solid #000;
	overflow: hidden;
}
#level-form {
	font-size: 0.7em;
}
#level-form input,
#level-form textarea {
	padding: 2px;
}
</style>
<script>
var $ = window.$;
const COLS = 16;
const ROWS = 31;
const BRICK_SIZE = 32;
const bricksBarArray = [
	// nr klocka, nr obrazka, nazwa
	[0, 1, 'PUSTE POLE'],
	[3, 3, 'ŚCIANA'],
	[30, 32, 'NIC'],
	[2, 2, 'KRATKA'],
	[4, 4, 'ŚRÓBKA'],

	[6, 6, 'NABOJE'],
	[12, 41, 'KLUCZ'],
	[13, 47, 'DRZWI'],
	[14, 9, 'ZIEMIA'],
	[19, 54, 'KRATKA ŚLIZG'],

	[20, 52, 'BOMBA'],
	[27, 31, 'NIESPODZIANKA'],
	[28, 38, 'MAGNEZ LEWO'],
	[29, 37, 'MAGNEZ PRAWO'],
	[49, 53, 'ŻYCIE'],

	[7, 63, 'PTAK W LEWO'],
	[8, 61, 'PTAK W PRAWO'],
	[10, 62, 'PTAK W GÓRĘ'],
	[11, 60, 'PTAK W DÓŁ'],
	[69, 29, 'OCZY'],

	[21, 45, 'DZIAŁKO W LEWO'],
	[22, 43, 'W PRAWO'],
	[23, 42, 'W GÓRĘ'],
	[24, 46, 'W DÓŁ'],
	[33, 70, 'DZIAŁKO OBROTOWE'],

	[35, 74, 'DZIAŁKO CIĄGŁE LEWO'],
	[36, 72, 'DZIAŁKO CIĄGŁE PRAWO'],
	[37, 71, 'DZIAŁKO CIĄGŁE GÓRA'],
	[38, 73, 'DZIAŁKO CIĄGŁE DÓŁ'],
	[34, 66, 'DZIAŁKO CHODZĄCE W LEWO'],

	[39, 78, 'BLASTER W LEWO'],
	[40, 76, 'BLASTER W PRAWO'],
	[41, 75, 'BLASTER GÓRA'],
	[42, 77, 'BLASTER DÓŁ'],
	[63, 67, 'DZIAŁKO CHODZACE W PRAWO'],

	[25, 64, 'POTWOREK PRAWOSTRONNY'],
	[26, 65, 'POTWOREK LEWOSTRONNY'],
	[31, 69, 'FIRE PTAK LEWO'],
	[32, 68, 'FIRE PTAK PRAWO'],
	[0, 1, ''],

	[43, 79, 'LUSTRO 1'],
	[44, 80, 'LUSTRO 2'],
	[45, 81, 'LUSTRO 3'],
	[46, 82, 'LUSTRO 4'],
	[47, 83, 'LUSTRO 5'],
	[48, 84, 'LUSTRO 6'],
	[64, 85, 'LUSTRO 7'],
	[65, 86, 'LUSTRO 8'],
	[66, 87, 'LUSTRO 9'],
	[67, 88, 'LUSTRO 10'],

	[5, 5, 'STATEK'],
	[1, 15, 'ROBBO W LEWO']
];

export default {
	name: 'Editor',
	async mounted() {
		this.init();
	},
	beforeDestroy() {
		if (this.gameInstance) {
			this.gameInstance.stop();
		}
		$(window).off('scroll', this.calculateMenuPos);
	},
	components: {},
	data() {
		return {
			game: void 0,
			levelData: {
				active: '0',
				created: '',
				difficulty: '0',
				id: '',
				leveldata: new Array(496).fill(0),
				modified: '',
				name: '',
				note: '',
				score: 0,
				score_count: 0,
				showed_times: 0,
				created_by_id: 0
			},
			levelId: 0,
			gameInstance: void 0,

			testLevelData: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], // eslint-disable-line prettier/prettier
			currentBrick: 0,
			currentImage: 1,
			isMouseDown: false,

			busy: false,
			error: ''
		};
	},
	computed: {
		isSaveEnabled() {
			return (
				this.$store.state.userData &&
				(this.levelData.created_by_id == this.$store.state.userData.id ||
					this.$store.state.userData.group_id == 1 ||
					this.levelData.created_by_id == 0)
			);
		}
	},
	watch: {
		'$route.params.id': function(id) {
			if (id) {
				id = +id;
			}
			this.levelId = id;
		},
		async levelId(id) {
			if (this.levelId) {
				await this.loadLevel(id);
			}
			else {
				this.resetData();
			}
			this.repaintLevel();
		}
	},

	methods: {
		async init() {
			this.createEditor();

			$('#left-menu').css({
				left: $('#editor-grid').offset()['left'] - 32 * 5 + 'px'
			});
			this.calculateMenuPos();
			$(window).on('scroll', this.calculateMenuPos);

			let id = this.$route.params.id;
			this.levelId = +id;
		},

		resetData() {
			this.levelData = {
				active: '0',
				difficulty: '0',
				id: '',
				leveldata: new Array(496).fill(0).join(),
				name: '',
				note: '',
				created_by_id: '0'
			};
			this.clearLevel();
		},

		calculateMenuPos() {
			var windowY = $(window).scrollTop() + 32;
			var editorY = $('#editor-grid').offset()['top'];
			if (windowY < editorY) windowY = editorY;

			var leftMenuHeight = $('#left-menu').height();
			var editorHeight = $('#editor-grid').height();
			if (windowY > editorY + editorHeight - leftMenuHeight) {
				windowY = editorY + editorHeight - leftMenuHeight;
			}

			windowY -= editorY;

			$('#left-menu')
				.stop()
				.animate({ top: windowY + 'px' }, 200);
		},

		loadLevel(id) {
			return fetch('/api/v1/levels/' + id + '?nocounter')
				.then(data => data.json())
				.then(data => {
					this.levelData = data.lvlData;
					console.log(data.lvlData);
				});
		},

		async confirmSave() {
			let newName = this.levelData.name + " - copy";
			let result = await this.$bvModal.msgBoxConfirm(
				`This will create a copy of level? - ${newName}`
			);
			if (result) {
				this.levelData.name = newName;
				this.saveLevel(true);
			}
		},

		async confirmDelete() {
			let result = await this.$bvModal.msgBoxConfirm(
				`Are you sure, this action can't be reverted?`
			);
			if (result) {
				this.error = '';
				let options = {
					method: 'DELETE',
					cache: 'no-cache',
					redirect: 'error'
				};
				let res = await fetch('/api/v1/levels/' + this.levelData.id, options);
				let data = await res.json();
				if (data && data.status == 'ok') {
					this.$router.push('/my-levels');
				} else {
					this.error = (data && data.message) || 'Unexpected error';
				}
			}
		},

		async saveLevel(asNew) {

			if (!this.levelData.id) {
				asNew = true;
			}

			this.busy = true;
			this.error = '';
			try {
				var postData = {
					id: this.levelData.id,
					active: this.levelData.active,
					name: this.levelData.name,
					note: this.levelData.note,
					difficulty: this.levelData.difficulty
				};

				// build level data
				let cellData = '';
				for (var r = 0; r < ROWS; r++)
					for (var c = 0; c < COLS; c++)
						cellData += this.getCellValue(c, r) + ',';
				postData['leveldata'] = cellData.substr(0, cellData.length - 1);
				let options = {
					method: asNew ? 'POST' : 'PATCH',
					cache: 'no-cache',
					redirect: 'error',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(postData)
				};
				let res;
				if (asNew) {
					// create
					res = await fetch('/api/v1/levels/', options);
				} else {
					// update
					res = await fetch('/api/v1/levels/' + this.levelData.id, options);
				}
				let data = await res.json();
				if (data && data.status == 'ok') {
					this.levelId = data.levelId;
				} else {
					this.error = (data && data.message) || 'Unexpected error';
				}
			} catch (e) {
				this.error = 'Unexpected error';
			}
			this.busy = false;
		},

		repaintLevel() {
			var arr = this.levelData.leveldata.split(',');
			var i = 0;
			for (var r = 0; r < ROWS; r++) {
				for (var c = 0; c < COLS; c++) {
					this.setCellValue(c, r, arr[i] * 1);
					i++;
				}
			}
		},

		clearTest() {
			this.gameInstance.stop();
		},

		testLevel() {
			// build level data
			// eslint-disable-next-line no-undef
			// $('#game-canvas').show();
			let cellData = '';
			for (let r = 0; r < ROWS; r++)
				for (let c = 0; c < COLS; c++) {
					cellData += this.getCellValue(c, r) + ',';
				}
			cellData = cellData.substr(0, cellData.length - 1);
			var out = [];
			cellData.split(',').forEach(function(v) {
				out.push(+v);
			});
			//console.log(cellData);

			// if (this.gameInstance) {
			// 	this.gameInstance.prepareTestLevel(out);
			// } else {
			// eslint-disable-next-line no-undef
			this.gameInstance = new Game({
				containerId: 'game-canvas',
				width: 576,
				height: 448,
				testLevelData: out,
				rootFolder: 'robbo/',
				levels: [
					'./levels/levels2' // RobboLevels2
				]
			});
			// }
			$('#game-canvas').focus();
			$('#game-canvas').click();

			//	var game = $('#robbo-game').get(0);
			//	game.setLevelData(cellData, 16, 31);
		},

		createEditor() {
			let $ = window.$;
			console.log('Rozpoczynam tworzenie edytora.');
			$('#editor-grid').width(COLS * BRICK_SIZE);
			for (var r = 0; r < ROWS; r++) {
				// creating row
				let rowEl = $('<tr></tr>').addClass('editor-row');
				for (var c = 0; c < COLS; c++) {
					// creating cell
					let cellEl = $('<td></td>').addClass('editor-cell');
					cellEl.css(
						'background-image',
						'url(/img/game-blocks/robboblkc_1.png)'
					);
					cellEl
						.data('val', 0)
						.data('img', 1)
						.data('x', c)
						.data('y', r);
					let that = this;
					cellEl.mousedown(function() {
						that.isMouseDown = true;
						// console.log(this.currentBrick);
						that.fillCell(this, that.currentBrick, that.currentImage);
					});
					cellEl.mouseup(() => {
						this.isMouseDown = false;
					});
					cellEl.mouseover(function() {
						if (that.isMouseDown)
							that.fillCell(this, that.currentBrick, that.currentImage);
					});
					cellEl.click(function() {
						that.fillCell(this, that.currentBrick, that.currentImage);
					});
					// check if most on the right cell
					if (c == COLS - 1) cellEl.addClass('most-right-cell');
					// check if bottom cell
					if (r == ROWS - 1) cellEl.addClass('most-bottom-cell');
					rowEl.append(cellEl);
				}
				$('#editor-grid').append(rowEl);
			}
			this.fillBarWithBricks();
		},

		clearLevel() {
			for (var r = 0; r < ROWS; r++)
				for (var c = 0; c < COLS; c++) {
					this.setCellValue(c, r, 0);
				}
			console.log('clean done');
		},

		fillCell(cellEl, val, img) {
			$(cellEl).css(
				'background-image',
				'url(/img/game-blocks/robboblkc_' + img + '.png)'
			);
			$(cellEl).data('val', val);
		},

		getCell(x, y) {
			return $(
				'#editor-grid .editor-row:eq(' + y + ') .editor-cell:eq(' + x + ')'
			);
		},

		getCellValue(x, y) {
			return $(
				'#editor-grid .editor-row:eq(' + y + ') .editor-cell:eq(' + x + ')'
			).data('val');
		},

		setCell(x, y, el) {
			$(
				'#editor-grid .editor-row:eq(' + y + ') .editor-cell:eq(' + x + ')'
			).replaceWith(el);
		},

		setCellValue(x, y, val) {
			let cellEl = $(
				'#editor-grid .editor-row:eq(' + y + ') .editor-cell:eq(' + x + ')'
			);
			cellEl.data('val', val);
			let img;
			if (val == 0) {
				img = 1;
			} else {
				img = 3;
				for (let i = 0; i < bricksBarArray.length; i++)
					if (bricksBarArray[i][0] == val) {
						img = bricksBarArray[i][1];
						break;
					}
			}
			cellEl.css(
				'background-image',
				'url(/img/game-blocks/robboblkc_' + img + '.png)'
			);
		},

		fillBarWithBricks() {
			$('#bricks-bar').width(4 * BRICK_SIZE);
			let rowEl = false;
			for (let i = 0; i < bricksBarArray.length; i++) {
				let c = i % 4;
				// let r = i / 5;
				if (!rowEl || c == 0) {
					rowEl = $('<tr></tr>').addClass('editor-row');
					if (rowEl) {
						$('#bricks-bar').append(rowEl);
					}
				}

				let b = bricksBarArray[i];
				// creating cell
				let cellEl = $('<td></td>').addClass('editor-cell');
				cellEl.css(
					'background-image',
					'url(/img/game-blocks/robboblkc_' + b[1] + '.png)'
				);
				cellEl.data('val', b[0]);
				cellEl.data('img', b[1]);
				cellEl.attr('title', b[2]);
				let that = this;
				cellEl.click(function() {
					that.currentBrick = $(this).data('val');
					console.log(that.currentBrick);
					that.currentImage = $(this).data('img');
				});
				rowEl.append(cellEl);
			}
		/*
			for(var r = 0; r < 11; r++)
			{
				// creating row 
				row = $("<tr></tr>").addClass("editor-row");
				for(var c = 0; c < 5; c++)
				{
					// creating cell 
					cell = $("<td></td>").addClass("editor-cell");
					cell.css('background-image', "url(/editor/bricks/robboblkc_"+(c+1+r*5)+".png)")
					cell.data("val", c+r*5);
					cell.click(function(){
						currentBrick = $(this).data('val');
					});
					// check if most on the right cell
					if(c == COLS - 1) cell.addClass('most-right-cell');
					// check if bottom cell
					if(r == ROWS - 1) cell.addClass('most-bottom-cell');
					row.append(cell);
				}
				$('#bricks-bar').append(row);
			}
		*/
		}
/*
		runGame() {
			let levelData = this.levelData.leveldata.split(',').map(v => +v);
			// eslint-disable-next-line no-undef
			this.gameInstance = new Game({
				containerId: "game-canvas", 
				width: 576,
				height: 448,
				rootFolder: "robbo/",
				testLevelData: levelData,
				debug: false,
				levels: [
					"./levels/levels",      // RobboLevels
					"./levels/levels2",     // RobboLevels2
					"./levels/cejsoft",     // CejsoftLevels
					"./levels/boss",        // BossLevels
					"./levels/653924",      // Levels653924
					"./levels/antic-soft",  // AnticSoftLevels
					"./levels/janusoft",    // JanusoftLevels
					"./levels/robbocop",    // RobbocopLevels
				]
			});
		}
*/
	}
};
</script>
